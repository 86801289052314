
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import UserData from "@/components/UserData.vue";
import { IonButton,IonPage } from "@ionic/vue";

export default defineComponent({
  name: "PartyLevels",
  components: {
    IonButton,
    UserData,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    router() {
      return router;
    },

    nivel() {
      return store.state.userData.nivel || "";
    },

    rutaDepto() {
      const _t = this as any;

      if (_t.nivel.length >= 4) return "/partyList/" + _t.nivel.substr(0, 4);
      else return "/pickDepto";
    },

    rutaMpio() {
      const _t = this as any;

      if (_t.nivel.length == 6) return "/partyList/" + _t.nivel;
      else if (_t.nivel.length == 4) return "/pickMpio/" + _t.nivel;
      else return "/pickDeptoMpio";
    },
  },
  methods: {},
});
