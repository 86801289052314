<template>
  <ion-page><cyan-page-wrapper title="Elegir nivel" backRoute="/home" nav1="MODO PARTIDO">
      <div class="homeButtonContainer">
        <user-data class="cabeceraUsuario mobileShowFlex" />
        <div class="dummyDiv mobileHideBlock"></div>

        <ion-button
          expand="block"
          strong="true"
          size="large"
          @click="router.replace('/partyList/SV')"
          >CONSEJO NACIONAL</ion-button
        >

        <ion-button
          expand="block"
          strong="true"
          size="large"
          @click="router.replace(rutaDepto)"
          >CONSEJO DEPARTAMENTAL</ion-button
        >

        <ion-button
          expand="block"
          strong="true"
          size="large"
          @click="router.replace(rutaMpio)"
          >CONSEJO MUNICIPAL</ion-button
        >
        <div class="dummyDiv"></div>
      </div>
  </cyan-page-wrapper></ion-page>
</template>

<script lang="ts">
import CyanPageWrapper, {
  router,
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";
import UserData from "@/components/UserData.vue";
import { IonButton,IonPage } from "@ionic/vue";

export default defineComponent({
  name: "PartyLevels",
  components: {
    IonButton,
    UserData,
    IonPage,
    CyanPageWrapper,
  },
  computed: {
    router() {
      return router;
    },

    nivel() {
      return store.state.userData.nivel || "";
    },

    rutaDepto() {
      const _t = this as any;

      if (_t.nivel.length >= 4) return "/partyList/" + _t.nivel.substr(0, 4);
      else return "/pickDepto";
    },

    rutaMpio() {
      const _t = this as any;

      if (_t.nivel.length == 6) return "/partyList/" + _t.nivel;
      else if (_t.nivel.length == 4) return "/pickMpio/" + _t.nivel;
      else return "/pickDeptoMpio";
    },
  },
  methods: {},
});
</script>

<style scoped>
.rel {
  position: relative;
}
.homeButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: auto;
  bottom: 0;
  position: absolute;
  top: 0;
  left: 2vw;
  right: 2vw;
}
.dummyDiv {
  visibility: hidden;
  width: 0;
  height: 0;
}
ion-button {
  height: 12vh;
}
@media (max-height: 400px) {
  .cabeceraUsuario  {
    display: none;
  }
  .dummyDiv {
    display:block;
  }
}
</style>
